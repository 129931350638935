import { defineComponent } from "vue";
import "@/styles/common.less";
export default defineComponent({
    name: "h3-company",
    computed: {
        isLoginMobile() {
            return window.location.href.indexOf("/login-mobile") > -1;
        },
    },
    methods: {
        gotoH3() {
            window.open(`${window.location.origin}/site/`);
        },
        goGov() {
            window.open("https://beian.miit.gov.cn");
        },
    },
});
