import { createStore } from 'vuex';
import { globalStorage } from '@/utils/storage/base';
import { setCookie2, H3EngineCode, TokenKey, } from '../utils';
import { loginByPassword, loginByPasswordWechat, loginByCorp, loginByCode, wechatOaAuth } from '@/apis/index';
// 0	成功
// 200001	登录方式不可用
// 200101	输入的参数为空
// 200201	未授权
// 200301	已授权
// 200401	企业被禁用
// 200402	企业正在迁移
// 200403	企业被归档
// 200404	正在安装
// 200501	用户不存在
// 200601	企业不存在
// 200701	扫码，钉钉错误
// 200801	钉钉oa免登错误
// 200802	h3sceret错误
// 200901	钉钉客户端免登错误
// 201001	选择企业页面停留时间过长，token超期
// 201101	验证码错误
// 201102	发送验证码次数过多
// 201103	发送验证码失败
// 201201	密码错误
// 201202	密码错误次数太多
// 202100	人机验证，通过，实际使用中不会返回这个错误码
// 202200	人机验证，通过，实际使用中不会返回这个错误码
// 202400	人机验证，不能确定，需要唤醒滑块验证
// 202800	人机验证，第一次，有风险
// 202900	人机验证，第二次，有风险
// 203001	更新密码失败
// 200002	系统错误
// 200102	token超期
export default createStore({
    state: {
        environment: 'h3',
    },
    getters: {
        getEnvironment(state) {
            return state.environment;
        },
    },
    mutations: {
        LoginAction(state, payload) {
            // 配合移动端消息通知身份验证
            setCookie2(TokenKey, payload.token);
            // 20220429 后台说不能用enginecode 解决外部组织切换vessel错误
            setCookie2(H3EngineCode, payload.enginecode);
            globalStorage.setToLocal(H3EngineCode, payload.enginecode, {
                expired: 8 * 60 * 60 * 1000,
                compile: false
            });
            // 缓存在本地以备自动登录时使用
            globalStorage.setToLocal(TokenKey, payload.token, {
                expired: 8 * 60 * 60 * 1000,
                compile: false
            });
        },
        SetEnvironment(state, payload) {
            state.environment = payload;
        }
    },
    actions: {
        loginWithCorpAction(context, params) {
            return new Promise((resolve, reject) => {
                loginByCorp(params).then(res => {
                    if (res.errcode === 0) {
                        context.commit('LoginAction', res.result);
                        resolve(res.result);
                    }
                    else {
                        // message.error(res.errmsg);
                        reject(res.errmsg || '登录失败');
                    }
                }).catch(() => {
                    reject('请检查你的网络');
                });
            });
        },
        loginWithPassWechatAction(context, params) {
            return new Promise((resolve, reject) => {
                loginByPasswordWechat(params).then(res => {
                    if (res.errcode === 0) {
                        context.commit('LoginAction', res.result);
                    }
                    else {
                        // reject(res.errmsg || '登录失败');
                    }
                    resolve(res);
                }).catch(() => {
                    reject('请检查你的网络');
                });
            });
        },
        loginWithPassAction(context, params) {
            return new Promise((resolve, reject) => {
                loginByPassword(params).then(res => {
                    if (res.errcode === 0) {
                        context.commit('LoginAction', res.result);
                    }
                    else {
                        // reject(res.errmsg || '登录失败');
                    }
                    resolve(res);
                }).catch(() => {
                    reject('请检查你的网络');
                });
            });
        },
        loginWithCodeAction(context, params) {
            return new Promise((resolve, reject) => {
                loginByCode(params).then(res => {
                    if (res.errcode === 0) {
                        context.commit('LoginAction', res.result);
                        resolve(res);
                    }
                    else {
                        reject(res.errmsg || '登录失败');
                    }
                }).catch(() => {
                    reject('请检查你的网络');
                });
            });
        },
        loginWithWechatAction(context, params) {
            return new Promise((resolve, reject) => {
                wechatOaAuth(params).then(res => {
                    if (res.errcode === 0) {
                        context.commit('LoginAction', res.result);
                        resolve(res);
                    }
                    else {
                        reject(res.errmsg || '登录失败');
                    }
                }).catch(() => {
                    reject('请检查你的网络');
                });
            });
        },
    },
});
