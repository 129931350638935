import { createApp } from 'vue';
import App from './App.vue';
import store from './store/index';
import router from './router/index';
import oAuth from './config/auth';
import { installAntd } from './plugins';
import './config/proxy';
// 移动端适配
import 'amfe-flexible';
(async () => {
    const ret = await oAuth();
    if (ret) {
        const app = createApp(App);
        installAntd(app);
        app.use(store);
        app.use(router);
        router.isReady().then(() => {
            app.mount('#app');
        });
    }
    else {
        console.log('验证不通过');
    }
})();
