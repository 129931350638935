// todo
const AuthWechat = () => import("@/views/auth-mobile/wechat.vue");
const AuthLogin = () => import("@/views/auth-mobile/login.vue");
const AuthIdentifyCode = () => import("@/views/auth-mobile/identify-code.vue");
const AuthRegister = () => import("@/views/auth-mobile/register.vue");
const AuthCorpSelect = () => import("@/views/auth-mobile/corp-select.vue");
const AuthCorpCreate = () => import("@/views/auth-mobile/corp-create.vue");
const AuthPasswordSet = () => import("@/views/auth-mobile/password-set.vue");
const AuthPasswordReset = () => import("@/views/auth-mobile/password-reset.vue");
const routers = [
    // 微信登录跳转中间页(业务逻辑判断)
    {
        path: "/auth-mobile/wechat",
        name: "AuthWechat",
        meta: { title: '微信授权' },
        component: AuthWechat
    },
    // 登录
    {
        path: "/auth-mobile/login",
        name: "AuthLogin",
        meta: { title: '登录' },
        component: AuthLogin
    },
    // 获取验证码
    {
        path: "/auth-mobile/identify-code",
        name: "AuthIdentifyCode",
        meta: { title: '获取验证码' },
        component: AuthIdentifyCode
    },
    // 注册
    {
        path: "/auth-mobile/register",
        name: "AuthRegister",
        meta: { title: '注册' },
        component: AuthRegister
    },
    // 选择企业
    {
        path: "/auth-mobile/corp-select",
        name: "AuthCorpSelect",
        meta: { title: '选择企业' },
        component: AuthCorpSelect
    },
    // 创建企业
    {
        path: "/auth-mobile/corp-create",
        name: "AuthCorpCreate",
        meta: { title: '创建企业' },
        component: AuthCorpCreate
    },
    // 设置密码
    {
        path: "/auth-mobile/password-set",
        name: "AuthPasswordSet",
        meta: { title: '设置密码' },
        component: AuthPasswordSet
    },
    // 密码重置
    {
        path: "/auth-mobile/password-reset",
        name: "AuthPasswordReset",
        meta: { title: '密码重置' },
        component: AuthPasswordReset
    }
];
export default routers;
