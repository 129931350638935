// PC端组件
import { Button, Cascader, Checkbox, Form, Input, List, Avatar, Tooltip, Modal, Divider } from 'ant-design-vue';
// 移动端组件
import { Button as VButton, Form as VForm, Field as VField, CellGroup as VCellGroup, Cell as VCell, Popup as VPopup, Toast as VToast, Picker as VPicker, Radio as VRadio, Dialog as VDialog, Loading as VLoading, PasswordInput as VPasswordInput, NumberKeyboard as VNumberKeyboard, NavBar as VNavBar, Checkbox as VCheckbox, Area as VArea, Overlay as VOverlay, } from 'vant';
export function installAntd(app) {
    app.component('Avatar', Avatar);
    app.component('AButton', Button);
    app.component('ACascader', Cascader);
    app.component('ACheckbox', Checkbox);
    app.component('AForm', Form);
    app.component('AFormItem', Form.Item);
    app.component('AInput', Input);
    app.component('AInputPassword', Input.Password);
    app.component('AList', List);
    app.component('AListItem', List.Item);
    app.component('ATooltip', Tooltip);
    app.component('AModal', Modal);
    app.component('ADivider', Divider);
    app.use(VButton);
    app.use(VPopup);
    app.use(VForm);
    app.use(VField);
    app.use(VCellGroup);
    app.use(VCell);
    app.use(VToast);
    app.use(VPicker);
    app.use(VRadio);
    app.use(VDialog);
    app.use(VLoading);
    app.use(VPasswordInput);
    app.use(VNumberKeyboard);
    app.use(VNavBar);
    app.use(VCheckbox);
    app.use(VArea);
    app.use(VOverlay);
}
