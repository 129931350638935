import { createRouter, createWebHashHistory } from 'vue-router';
import authMobile from './routes/auth.mobile';
import authPc from './routes/auth.pc';
import useScan from '@/uses/use-scan';
const ScanLogin = () => import('@/views/login-scan/index.vue');
const routes = [
    ...authMobile,
    ...authPc,
    {
        path: '/',
        component: ScanLogin,
        beforeEnter: () => {
            const params = useScan();
            // 默认跳转pass登录 钉钉企微使用scan(code)
            if (Object.keys(params).length <= 0) {
                return { path: '/login-pass' };
            }
        }
    }
];
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
router.afterEach(to => {
    window.document.title = to.meta.title || '氚云';
});
export default router;
