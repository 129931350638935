const LoginPass = () => import("@/views/login-pass/index.vue");
const ScanLogin = () => import("@/views/login-scan/index.vue");
const ResetPassword = () => import("@/views/password/reset.vue");
const Success = () => import("@/views/password/successful.vue");
const LoginSelectCorp = () => import("@/views/login-pass/select-corp.vue");
const AssociatedOrganization = () => import("@/views/associated-organization/validate.vue");
const AssociatedOrganizationEntry = () => import("@/views/associated-organization/entry.vue");
const AssorgMobile = () => import("@/views/associated-organization/validate-mobile.vue");
const LoginMobile = () => import("@/views/login-mobile/index.vue");
const LoginddCorp = () => import("@/views/login-ddcorp/index.vue");
const routers = [
    {
        path: "/login-pass",
        name: "Login",
        component: LoginPass
    },
    {
        path: "/login-scan",
        name: "ScanLogin",
        component: ScanLogin
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword
    },
    {
        path: "/reset-password-success",
        name: "Success",
        component: Success
    },
    {
        path: "/login-select-corp",
        name: "LoginSelectCorp",
        component: LoginSelectCorp
    },
    {
        path: "/associated-organization",
        name: "AssociatedOrganization",
        component: AssociatedOrganization
    },
    {
        path: "/associated-organization-entry",
        name: "AssociatedOrganizationEntry",
        component: AssociatedOrganizationEntry
    },
    {
        path: "/assorg-mobile",
        name: "AssorgMobile",
        component: AssorgMobile
    },
    {
        path: "/login-mobile",
        name: "LoginMobile",
        component: LoginMobile
    },
    {
        path: "/login-ddcorp",
        name: "LoginddCorp",
        component: LoginddCorp
    }
];
export default routers;
