import H3Company from "@/components/h3-company.vue";
import { setAuthorization, parseUrlParams, isMobile } from "@/utils";
import "@/styles/common.less";
export default {
    name: "App",
    components: {
        H3Company: H3Company,
    },
    data() {
        return {
            environment: "h3",
        };
    },
    created() {
        const link = document.querySelector("link[rel*='icon']");
        this.$store.commit("SetEnvironment", this.environment);
        // 外链手机验证码登录时为表单标题
        if (window.location.href.indexOf("/login-mobile") > -1) {
            return;
        }
        document.title = "氚云";
        link &&
            (link.href =
                "https://static.h3yun.com/common-resource/login/imgs/favicon.ico");
    },
    setup() {
        const params = parseUrlParams(window.location.href);
        const { token, redirecturl, shardkey, messageType } = params;
        if (token && redirecturl) {
            const url = decodeURIComponent(redirecturl);
            setAuthorization(token, shardkey);
            if (messageType === "AssociatedOrganization") {
                window.location.href = `/pc/system-integration.html#/version-info?frompath=associatedOrganization`;
            }
            else {
                window.location.replace(url);
            }
            return {
                visible: false,
            };
        }
        return {
            visible: true,
            isMobile,
        };
    },
};
